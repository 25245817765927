/**
 * 编辑器的全屏实现
 */
 import noScroll from 'no-scroll'

 export default class Fullscreen {
     constructor (quill, options = {}) {
         this.quill = quill
         this.options = options
         this.fullscreen = false
         this.editorContainer = this.quill.container.parentNode.parentNode
     }
 
     handle () {
         if (! this.fullscreen) {
             this.fullscreen = true
             this.editorContainer.className = 'ql-editor ql-blank editor-fullscreen'
             noScroll.on()
         }else{
             this.fullscreen = false
             this.editorContainer.className = 'ql-editor ql-blank'
             noScroll.off()
         }
     }
 }