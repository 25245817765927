<template>
  <div class="quill-editor-container"
  :style="'height:auto'"
  >
    <!-- <div
      v-if="oninput"
      style="
        text-align: center;
        font-size: 15px;
        background-color: #9d1d22;
        color: white;
      "
    >
      当前是内容编辑模式，点击此处退出 苹果手机点击完成退出
    </div> -->
    <quill-editor
      ref="quilleditor"
      v-if="showEditor"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    >
    </quill-editor>
    <div style="display: none">
      <input
        type="file"
        multiple
        accept="image/*"
        id="quill-editor-imageselect"
      />
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor, Quill } from "vue-quill-editor";
import Fullscreen from "@/utils/Fullscreen";

import { uploadfile } from "@/api/file";

import { Toast } from "vant";

// 配置参照这里（第一个模板）
// https://github.com/surmon-china/vue-quill-editor/blob/master/examples/01-example.vue
//import toolbar from './toolbar'

const Delta = Quill.import("delta");

Quill.register("modules/fullscreen", Fullscreen);

export default {
  name: "QuillEditor",
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    contentTmpType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showEditor: false,
      imgSign: {},
      content: this.value || "",
      oninput: false,
      editorOption: {
        modules: {
          // clipboard: {
          //     // 粘贴版，处理粘贴时候带图片
          //     matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
          //   },
        },
      },
    };
  },
  watch: {
    value(val) {
      this.content = val;
    },
  },
  mounted() {
    this.setEditorOption();
    let fileInput = document.querySelector("#quill-editor-imageselect");
    fileInput.addEventListener("change", () => this.FileInputChange(), false);
    if (this.contentTmpType) {
      const contentTmp = localStorage.getItem(
        "znkm-h5-editor-tmp-" + this.contentTmpType
      );
      if (contentTmp) {
        this.content = contentTmp;
      }
    }
  },
  methods: {
    // 获取图片上传签名（因为上传到腾讯云，所以要一些额外参数）

    // 设置配置信息
    setEditorOption() {
      // 当前组件实例
      //let vm = this
      this.editorOption = Object.assign(this.editorOption, {
        placeholder: "请插入内容...",
        modules: {
          fullscreen: {},
          toolbar: {
            container: [
              // ["image", "link"],
              // [{ color: [] }, { background: [] }],

              // // [{ size: ["small", false, "large", "huge"] }],

              // ["bold", "italic", "underline", "strike"],
              // [{ list: "ordered" }, { list: "bullet" }],
              // [{ align: [] }],
              // [{ indent: "-1" }, { indent: "+1" }],
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              //["clean"],
              //   ["fullscreen"],
            ],
            handlers: {
              fullscreen() {
                this.quill.getModule("fullscreen").handle();
              },
              // 自定义上传图片到服务器
              image: function () {
                // quill插件实例
                //let _self = this;
                // 创建上传文件input并触发
                //let fileInput = document.createElement("input");
                let fileInput = document.querySelector(
                  "#quill-editor-imageselect"
                );
                fileInput.value = "";
                // fileInput.setAttribute("type", "file");
                // fileInput.setAttribute("multiple", "multiple");
                // fileInput.setAttribute("accept", "image/*");

                fileInput.files == null;
                //fileInput.addEventListener("change", this.FileInputChange({fileInput:fileInput,_self:_self}),false);

                // fileInput.addEventListener("change",  () => {
                //   if (fileInput.files !== null) {
                //       alert("file not null")
                //   }
                //   alert("end chnage");
                // });
                fileInput.click();
              },
            },
          },
        },
      });
      this.$nextTick(() => {
        this.showEditor = true;
      });
    },
    //禁止复制图片
    handleCustomMatcher(node, Delta) {
      console.log("handle...");
      let ops = [];
      Delta.ops.forEach((op) => {
        console.log(op);
        if (op.insert && typeof op.insert === "string") {
          // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
          ops.push({
            insert: op.insert,
          });
        } else {
          if (op.insert.image.includes("file://")) {
            //本地图片会使文件file开头
            this.$message.warning("不允许粘贴图片,请手动上传");
          } else {
            ops.push({
              insert: op.insert,
            });
          }
        }
      });
      Delta.ops = ops;
      return Delta;
    },
    // 文本改变后同步回父组件绑定的值
    onEditorChange(event) {
      if (this.contentTmpType) {
        localStorage.setItem("znkm-h5-editor-tmp-" + this.contentTmpType, event.html);
      }
      this.$emit("input", event.html);
    },

    async FileInputChange() {
      let fileInput = document.querySelector("#quill-editor-imageselect");
      if (fileInput.files !== null) {
        const files = Array.from(fileInput.files).reverse();

        if (files.length > 9) {
          Toast("单次上传文件数量不能超过9张");
          return false;
        }
        Toast("编辑器中显示的是图片缩略图，方便编辑");

        for (const file of files) {
          Toast("正在上传图片..");
          const formData = new FormData();
          formData.append("file", file);
          let aresult = await uploadfile(formData);
          let url = aresult.data;

          let _self = this.$refs.quilleditor;
          const range = _self.quill.getSelection(true);
          _self.quill.updateContents(
            new Delta()
              .retain(range.index)
              .delete(range.length)
              .insert({ image: url })
          );
        }
        Toast("上传完成");
      }
    },

    onEditorBlur() {
      //失去焦点事件
      this.$emit("onInputHandler", false);
      this.oninput = false;
    },
    onEditorFocus() {
      //获得焦点事件
      this.$emit("onInputHandler", true);
      // Toast("");
      this.oninput = true;
    },
  },
};
</script>


<style lang="less" scoped>
.quill-editor-container{
  height: 100%;
}
.quill-editor{
  height: 100%;
}
.quill-editor /deep/.ql-toolbar.ql-snow{
  display: none;
}

.quill-editor /deep/.ql-toolbar.ql-snow + .ql-container.ql-snow{
  border-top: 1px solid #ccc;

}
.quill-editor /deep/ .ql-container {
  min-height: 250px;
}
.quill-editor /deep/ .ql-snow .ql-editor img {
  max-width: 20% !important;
  margin: 2px 10px 0px 10px;
}
</style>
